import { ReactNode } from "react";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { PasskeyErrorType } from "@gemini-ui/pages/SignIn/usePasskeySignin";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";

export const getErrorTitleCopy = (intl: IntlShape, errorType: PasskeyErrorType) => {
  switch (errorType) {
    case PasskeyErrorType.PasskeySigninDisabled:
      return intl.formatMessage({ defaultMessage: "Unable to sign in with passkey" });
    case PasskeyErrorType.ErrorProcessing:
      return intl.formatMessage({ defaultMessage: "Something went wrong" });
  }
};

export const getErrorDescriptionCopy = (intl: IntlShape, errorType: PasskeyErrorType) => {
  switch (errorType) {
    case PasskeyErrorType.PasskeySigninDisabled:
      return intl.formatMessage(
        defineMessage({
          defaultMessage:
            "You haven’t enabled “Sign in with passkey” in your security settings. <HelpCenterLinkArticlesSecurityKey>Learn more</HelpCenterLinkArticlesSecurityKey>",
        }),
        {
          HelpCenterLinkArticlesSecurityKey: (v: ReactNode) => (
            <HelpCenterLink article={Articles.SECURITY_KEY}>{v}</HelpCenterLink>
          ),
        }
      );
    case PasskeyErrorType.ErrorProcessing:
      return intl.formatMessage({
        defaultMessage:
          "We couldn't complete your sign-in with passkey request. Please try again or use a different sign-in method.",
      });
  }
};
