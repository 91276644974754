import { Fragment } from "react";
import { useTheme } from "@emotion/react";
import { Flex, Spacer, Spacing, Text } from "@gemini-ui/design-system";
import { PasskeySignInCard } from "@gemini-ui/pages/SignIn/PasskeySignInCard";
import { useIntl } from "@gemini-ui/utils/intl";

type AlternateSignInOptionsProps = { startSignIn: () => void; isLoading: boolean };

export const AlternateSignInOptions = ({ startSignIn, isLoading }: AlternateSignInOptionsProps) => {
  const { colorScheme } = useTheme();
  const { intl } = useIntl();
  return (
    <Fragment>
      <Flex justifyContent="center" gap={Spacing.scale[2]} mt={2}>
        <Spacer style={{ background: colorScheme.border.primary, height: "1px", width: "100%" }} mt={1.5} />
        <Text color={colorScheme.content.secondary}>{intl.formatMessage({ defaultMessage: "OR" })}</Text>
        <Spacer style={{ background: colorScheme.border.primary, height: "1px", width: "100%" }} mt={1.5} />
      </Flex>
      <PasskeySignInCard startSignIn={startSignIn} isLoading={isLoading} />
    </Fragment>
  );
};
