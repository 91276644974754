import SupportLink from "@gemini-ui/components/SupportLink";
import { defineMessage, IntlShape } from "@gemini-ui/utils/intl";
export const formatErrorMsg = (content, intl: IntlShape) => {
  const flatMessage = content[""]?.[0];
  if (!flatMessage) {
    return "";
  }
  switch (flatMessage) {
    case "_closed":
      return (
        <span>
          {" "}
          {intl.formatMessage(
            defineMessage({
              defaultMessage:
                "The user account you are trying to access has been closed. If you believe this is in error or have questions, please {supportLink}.",
            }),
            {
              supportLink: <SupportLink />,
            }
          )}
        </span>
      );
    default:
      return flatMessage;
  }
};
