import { useEffect, useRef, useState } from "react";

export function useCustomRef<T>() {
  const ref = useRef<T>(null);
  const setRefCallback = (node: T) => {
    ref.current = node;
  };

  const [unused, setIsLoaded] = useState(false);
  // ensures ref is available on first render if needed
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return { ref, setRefCallback };
}
