import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { AuthenticationRequest } from "@gemini-ui/constants/initialData";
import { usePasskeyAuth } from "@gemini-ui/pages/Authy/AuthyForm/usePasskeyAuth";
import axios, { AxiosError } from "@gemini-ui/services/axios";

export enum PasskeyErrorType {
  PasskeySigninDisabled = "passkeySignInDisabled",
  ErrorProcessing = "errorProcessing",
}

export const usePasskeySignin = (redirect: string, passkeyTestingEnabled: boolean) => {
  const { initiateAuth } = usePasskeyAuth(true);
  const [errorType, setErrorType] = useState<PasskeyErrorType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isPasswordlessEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CREATE_PASSKEYS_PASSWORDLESS
  );
  const startAuthProcess = (authRequest: AuthenticationRequest) => {
    const publicKeyCredentialRequestOptions = authRequest.assertion.assertion.publicKeyCredentialRequestOptions;
    const authSignedRequest = authRequest.signed;
    initiateAuth({
      onSuccess: handleSuccess,
      onError: handleError,
      publicKeyCredentialRequestOptions,
      authSignedRequest,
      redirect,
    });
  };

  const fetchProof = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get<AuthenticationRequest>("/signin/passwordless");
      // support retrying and opening webauthn right away on success
      if (response.data) {
        startAuthProcess(response.data);
      }
    } catch (e) {
      track(EVENTS.SIGNIN_WITH_PASSKEY_ERROR_FETCHING_PROOF.name, {
        [EVENTS.SIGNIN_WITH_PASSKEY_ERROR_FETCHING_PROOF.properties.Error]: e,
      });
      Sentry.captureException(e);
      setErrorType(PasskeyErrorType.ErrorProcessing);
    } finally {
      setIsLoading(false);
    }
  };

  const retrySignin = () => {
    setErrorType(null);
    fetchProof();
  };

  const handleSuccess = (data: { redirect: string }) => {
    track(EVENTS.PASSKEY_LOGIN_SUCCESS);
    window.location.assign(data?.redirect || "/");
  };

  const handleError = (e: AxiosError<{ error: string }> | DOMException) => {
    track(EVENTS.PASSKEY_LOGIN_FAILED.name, { [EVENTS.PASSKEY_LOGIN_FAILED.properties.Error]: e.message });
    if (e instanceof AxiosError && e.response.data?.error === PasskeyErrorType.PasskeySigninDisabled) {
      setErrorType(PasskeyErrorType.PasskeySigninDisabled);
    } else {
      setErrorType(PasskeyErrorType.ErrorProcessing);
    }
  };

  const startSignin = () => {
    track(EVENTS.SIGNIN_WITH_PASSKEY_CLICKED);
    if (isPasswordlessEnabled || passkeyTestingEnabled) {
      fetchProof();
    } else {
      setErrorType(PasskeyErrorType.ErrorProcessing);
    }
  };

  return { startSignin, errorType, setErrorType, retrySignin, isLoading };
};
