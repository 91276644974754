import { useCallback, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";
import { isProdUrl, isSandboxUrl } from "@gemini-ui/analytics";
import { optimizelyClient } from "@gemini-ui/analytics/optimizely";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { makeScript } from "@gemini-ui/utils/useScript";

interface SardineConfig {
  flow: string;
  sessionKey: string;
  userIdHash?: string;
}

export interface SardineContext {
  config: SardineConfig;
  updateConfig(config: Partial<SardineConfig>): void;
}

function useSardine(pathname: string) {
  const pageData = usePageData();
  const [sardine, setSardine] = useState(window.sardineContext);
  const isSubDomainUsageEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_USE_GEMINI_SUBDOMAIN_SARDINE
  );

  /** Converts the cookie to an object */
  function cookieAsObject(cookie: string) {
    try {
      const parameters = new URLSearchParams(cookie);
      const sessionKey = parameters.get("sessionKey");
      const userId = parameters.get("userId");
      return { sessionKey, userId };
    } catch (err) {
      console.error("useSardine cookieAsObject", err);
      return {};
    }
  }

  const sardineSubDomains = useMemo(() => {
    if (isSubDomainUsageEnabled) {
      if (isProdUrl()) {
        const PROD_API_SUB_DOMAIN = "a.gemini.com";
        const PROD_PIXEL_SUB_DOMAIN = "p.gemini.com";
        return {
          apiSubdomain: PROD_API_SUB_DOMAIN,
          pixelSubdomain: PROD_PIXEL_SUB_DOMAIN,
        };
      } else if (isSandboxUrl()) {
        const SANDBOX_API_SUB_DOMAIN = "a.sandbox.gemini.com";
        const SANDBOX_PIXEL_SUB_DOMAIN = "p.sandbox.gemini.com";
        return {
          apiSubdomain: SANDBOX_API_SUB_DOMAIN,
          pixelSubdomain: SANDBOX_PIXEL_SUB_DOMAIN,
        };
      } else {
        const STAGING_API_SUB_DOMAIN = "a.aurora7.net";
        const STAGING_PIXEL_SUB_DOMAIN = "p.aurora7.net";
        return {
          apiSubdomain: STAGING_API_SUB_DOMAIN,
          pixelSubdomain: STAGING_PIXEL_SUB_DOMAIN,
        };
      }
    } else {
      const DEV_HOST = "api.sandbox.sardine.ai";
      const PROD_HOST = "api.sardine.ai";
      const apiSubdomain = isProdUrl() ? PROD_HOST : DEV_HOST;
      return {
        apiSubdomain,
        pixelSubdomain: undefined,
      };
    }
  }, [isSubDomainUsageEnabled]);

  /** Sets up the Sardine JS SDK */
  const setupSardine = useCallback(() => {
    const sardineCookie = Cookies.get("SardineSession");
    if (pageData.templateProps.featureFlags.SardineDeviceIntelligenceEnabled && sardineCookie) {
      const { sessionKey, userId } = cookieAsObject(sardineCookie);
      if (sessionKey) {
        const { apiSubdomain, pixelSubdomain } = sardineSubDomains;
        const script = makeScript(`https://${apiSubdomain}/assets/loader.min.js`, {
          async: true,
          id: "sardine-context",
          onLoad: () => {
            window.sardineContext = window._Sardine?.createContext({
              clientId: pageData.sardine?.clientId,
              environment: isProdUrl() ? "production" : "sandbox",
              flow: location.pathname,
              parentElement: document.body,
              sessionKey: sessionKey,
              userIdHash: userId,
              apiSubdomain,
              pixelSubdomain,
            });
            setSardine(window.sardineContext);
          },
          onError: e => {
            Sentry.captureMessage(e.message, "warning");
          },
        });
        script.load();
      }
    }
  }, [
    pageData.templateProps.featureFlags.SardineDeviceIntelligenceEnabled,
    pageData.sardine?.clientId,
    sardineSubDomains,
  ]);

  /** First time set up */
  useEffect(() => {
    if (!sardine && !window.sardineContext) {
      setupSardine();
    }
  }, [sardine, setupSardine]);

  /** Update flow if pathname changes */
  useEffect(() => {
    if (sardine) {
      sardine.updateConfig({ flow: pathname });
    }
  }, [sardine, pathname]);

  /** Keep userIdHash up to date */
  useEffect(() => {
    if (sardine) {
      const sardineCookie = Cookies.get("SardineSession");
      const { userId } = cookieAsObject(sardineCookie);

      if (userId !== sardine.config.userIdHash) {
        sardine.updateConfig({ userIdHash: userId });
      }
    }
  }, [sardine]);

  return sardine;
}

export default useSardine;
