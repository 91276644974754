import { Alert, Button } from "@gemini-ui/design-system";
import { getErrorDescriptionCopy, getErrorTitleCopy } from "@gemini-ui/pages/SignIn/passkeyErrorCopy";
import { SigninTestIds } from "@gemini-ui/pages/SignIn/testIds";
import { PasskeyErrorType } from "@gemini-ui/pages/SignIn/usePasskeySignin";
import { useIntl } from "@gemini-ui/utils/intl";

type SigninWithPasskeyErrorProps = {
  errorType: PasskeyErrorType;
  onDismiss: () => void;
  retry: () => void;
};
export const SigninWithPasskeyError = ({ errorType, onDismiss, retry }: SigninWithPasskeyErrorProps) => {
  const { intl } = useIntl();
  const isBaseError = errorType === PasskeyErrorType.ErrorProcessing;
  const DismissButton = isBaseError ? Button.Secondary : Button.Primary;

  const title = getErrorTitleCopy(intl, errorType);
  const description = getErrorDescriptionCopy(intl, errorType);
  return (
    <Alert open>
      <Alert.Container>
        <Alert.Header>
          <Alert.Title>{title}</Alert.Title>
          <Alert.Description>{description}</Alert.Description>
        </Alert.Header>
        <Alert.Footer>
          <DismissButton data-testid={SigninTestIds.PasskeyErrorButtonBtn} onClick={onDismiss}>
            {intl.formatMessage({ defaultMessage: "Dismiss" })}
          </DismissButton>
          {isBaseError && (
            <Button.Primary data-testid={SigninTestIds.PasskeyTryAgainBtn} onClick={retry}>
              {intl.formatMessage({ defaultMessage: "Try again" })}
            </Button.Primary>
          )}
        </Alert.Footer>
      </Alert.Container>
    </Alert>
  );
};
