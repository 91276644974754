import { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { makeScript } from "@gemini-ui/utils/useScript";

export type AwsWafIntegrationType = {
  getToken: () => Promise<string>;
};

function useWAFIntegration(): { wafIntegration: AwsWafIntegrationType } {
  const [wafIntegration, setWafIntegration] = useState<AwsWafIntegrationType>(window.AwsWafIntegration);

  const setupWAF = useCallback(() => {
    const hostname = optimizelyClient.getFeatureVariableString(OPTIMIZELY_FEATURE_FLAGS.WEB_WAF_CHALLENGE, "hostname");

    const script = makeScript(`${hostname}/challenge.js`, {
      async: true,
      id: "waf-integration",
      onLoad: () => {
        setWafIntegration(window.AwsWafIntegration);
      },
      onError: e => {
        Sentry.captureMessage(e.message, "warning");
      },
    });
    script.load();
  }, []);

  useEffect(() => {
    const isEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_WAF_CHALLENGE);
    if (isEnabled) {
      setupWAF();
    }
  }, [setupWAF]);

  return { wafIntegration };
}

export default useWAFIntegration;
