
    import { render as __gemini_ui_render } from "@gemini-ui/renderer";
    import { Fragment, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
// eslint-disable-next-line no-restricted-imports
import { Formik } from "formik";
import queryString from "query-string";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import _SupportLink from "@gemini-ui/components/SupportLink";
import useWAFIntegration from "@gemini-ui/hooks/useWAFIntegration";
import { useCustomRef } from "@gemini-ui/pages/register/hooks/useCustomRef";
import OnboardingLayout from "@gemini-ui/pages/register/OnboardingLayout";
import { signInFormValidation } from "@gemini-ui/pages/SignIn/formValidation";
import { formatErrorMsg } from "@gemini-ui/pages/SignIn/helpers";
import RightNav from "@gemini-ui/pages/SignIn/rightNav";
import { SandboxMessage } from "@gemini-ui/pages/SignIn/SandboxMessage";
import { SignInForm } from "@gemini-ui/pages/SignIn/SignInForm";
import { handleExceptionWithRedirect, makeSignInRequest } from "@gemini-ui/pages/SignIn/utils";
import { useSentryTeam } from "@gemini-ui/sentry/hooks";
import { SENTRY_TEAMS } from "@gemini-ui/sentry/teams";
import { getError, getFormErrors } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";
import storage from "@gemini-ui/utils/storage";
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const registerUrl = jsRoutes.com.gemini.web.server.onboarding.controllers.services.RegisterController.get().url;
const SignInScreen = props => {
  const {
    intl
  } = useIntl();
  const {
    wafIntegration
  } = useWAFIntegration();
  const {
    showAlert
  } = useAlert();
  const [signInView, setSignInView] = useState("PRIMARY");
  const {
    ref: recaptcha,
    setRefCallback
  } = useCustomRef();
  useSentryTeam(SENTRY_TEAMS.Growth);
  useEffect(() => {
    const notification = storage.getFlashNotification();
    if (notification) {
      showAlert({
        type: notification.type,
        message: notification.message
      });
    }
  }, [showAlert]);
  function title() {
    const {
      oauthClient
    } = props;
    if (oauthClient) {
      return intl.formatMessage(defineMessage({
        id: "3HZjhY",
        defaultMessage: [{
          "type": 0,
          "value": "Connect "
        }, {
          "type": 1,
          "value": "oauthClientName"
        }, {
          "type": 0,
          "value": " with your Gemini account"
        }]
      }), {
        oauthClientName: oauthClient.name
      });
    } else {
      const {
        sandboxMode
      } = props;
      return sandboxMode ? intl.formatMessage({
        id: "r6G0F2",
        defaultMessage: [{
          "type": 0,
          "value": "Gemini sandbox"
        }]
      }) : signInView === "PRIMARY" ? intl.formatMessage({
        id: "SQJto2",
        defaultMessage: [{
          "type": 0,
          "value": "Sign in"
        }]
      }) : intl.formatMessage({
        id: "PwaN2o",
        defaultMessage: [{
          "type": 0,
          "value": "Welcome"
        }]
      });
    }
  }
  const extractClientId = url => {
    const getSearch = u => u.includes("?") && u.split("?")[1];
    const getParams = s => queryString.parse(decodeURIComponent(s));
    const getOAuthClientId = params => ({
      ...params,
      ...(params.redirect ? getParams(getSearch(params.redirect)) : {})
    }).client_id;
    return getOAuthClientId(getParams(getSearch(url)));
  };
  const formatRegisterUrl = (registerUrl, oauthClientId) => {
    if (oauthClientId) {
      return `${registerUrl}?clientId=${oauthClientId}`;
    }
    return registerUrl;
  };
  const handleSubmit = async (values, _ref) => {
    let {
      setSubmitting,
      setErrors,
      setFieldValue
    } = _ref;
    const {
      redirect
    } = props;
    setSubmitting(true);
    if (wafIntegration) {
      try {
        await wafIntegration.getToken();
      } catch (e) {
        Sentry.captureException(e);
        showAlert({
          type: AlertTypes.ERROR,
          message: getError(e)
        });
        setSubmitting(false);
        // block submission if failure setting the token occurs
        return;
      }
    }
    try {
      await makeSignInRequest(values, redirect);
    } catch (exception) {
      if (!handleExceptionWithRedirect(exception, values.email)) {
        const formErrors = getFormErrors(exception);
        if (formErrors) {
          setErrors({
            password: formatErrorMsg(formErrors, intl)
          });
        } else {
          const error = getError(exception);
          showAlert({
            id: "submission-error",
            type: AlertTypes.ERROR,
            message: error
          });
          Sentry.captureException(exception);
        }
      }
      setSubmitting(false);
      recaptcha.current.reset();
      // don't let formik re-run validation (it removes the field error)
      setFieldValue("g-recaptcha-response-v2", "", false);
      setFieldValue("g-recaptcha-response-v3", "", false);
    }
  };
  const onRecaptchaResolve = formikMethods => () => {
    const {
      setFieldValue
    } = formikMethods;
    const {
      siteKeyV3
    } = props;
    if (siteKeyV3) {
      recaptcha.current.executeV3().then(token => {
        setFieldValue("g-recaptcha-response-v2", recaptcha.current.getResponse());
        setFieldValue("g-recaptcha-response-v3", token);
      }).catch(e => {
        showAlert({
          type: AlertTypes.ERROR,
          message: _jsx(Fragment, {
            children: intl.formatMessage(defineMessage({
              id: "QEXwV0",
              defaultMessage: [{
                "type": 0,
                "value": "Unable to verify device, please "
              }, {
                "type": 8,
                "value": "SupportLink",
                "children": []
              }, {
                "type": 0,
                "value": "."
              }]
            }), {
              SupportLink: () => _jsx(_SupportLink, {})
            })
          }),
          id: "reset-password-error"
        });
        Sentry.captureMessage(`Sign in form: Something went wrong with the recaptcha v3 execution on the client error: ${e}`, "error");
      });
    } else {
      setFieldValue("g-recaptcha-response-v2", recaptcha.current.getResponse());
    }
  };
  const {
    sandboxMode,
    signInForm,
    oauthClient,
    redirect,
    siteKeyV2,
    siteKeyV3
  } = props;
  const verboseRegister = oauthClient != null;
  const query = queryString.parse(decodeURIComponent(window.location.search));
  const referralCode = signInForm.values.referral ? signInForm.values.referral : query.referral;
  const oauthClientId = extractClientId(window.location.href);
  const formattedRegisterUrl = formatRegisterUrl(registerUrl, oauthClientId);
  return _jsxs(OnboardingLayout, {
    title: title(),
    rightHeader: _jsx(RightNav, {
      oauthClientId: oauthClientId
    }),
    children: [sandboxMode && _jsx(SandboxMessage, {}), _jsx(Formik, {
      initialValues: {
        csrfToken: signInForm.csrfToken,
        email: signInForm.values.email,
        password: signInForm.values.password || "",
        rememberEmail: signInForm.values.rememberEmail,
        referral: referralCode,
        oauthClientId,
        ["g-recaptcha-response-v2"]: signInForm.values["validationIds.g-recaptcha-response-v2"],
        ["g-recaptcha-response-v3"]: signInForm.values["validationIds.g-recaptcha-response-v3"]
      },
      validationSchema: signInFormValidation(intl),
      onSubmit: handleSubmit,
      validateOnBlur: true,
      render: _ref2 => {
        let {
          values,
          errors,
          touched,
          handleChange,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          handleBlur
        } = _ref2;
        return _jsx(SignInForm, {
          handleSubmit: handleSubmit,
          values: values,
          errors: errors,
          touched: touched,
          handleChange: handleChange,
          isSubmitting: isSubmitting,
          setFieldValue: setFieldValue,
          siteKeyV2: siteKeyV2,
          siteKeyV3: siteKeyV3,
          setRecaptcha: setRefCallback,
          onRecaptchaResolve: onRecaptchaResolve,
          redirect: redirect,
          setSignInView: setSignInView,
          signInView: signInView,
          handleBlur: handleBlur
        });
      }
    }), verboseRegister && _jsxs(Fragment, {
      children: [_jsx("hr", {
        style: {
          marginBottom: 50,
          marginTop: 50
        }
      }), _jsx("p", {
        className: "FieldLabel",
        children: intl.formatMessage({
          id: "mdnhvE",
          defaultMessage: [{
            "type": 0,
            "value": "Need a Gemini account?"
          }]
        })
      }), _jsx("p", {
        children: intl.formatMessage({
          id: "rpafF8",
          defaultMessage: [{
            "type": 0,
            "value": "Sign up today for instant access to Gemini’s global exchange, the most trusted place to buy and sell cryptocurrencies"
          }]
        })
      }), _jsx("a", {
        href: formattedRegisterUrl,
        children: intl.formatMessage({
          id: "gggFqg",
          defaultMessage: [{
            "type": 0,
            "value": "Sign up here →"
          }]
        })
      })]
    })]
  });
};
SignInScreen.displayName = "SignInScreen";
export const RenderComponent = SignInScreen;
export default SignInScreen;
    __gemini_ui_render(RenderComponent);
  