import { ChangeEvent, FocusEvent, FormEvent, Fragment } from "react";
import { IconProfileFilled } from "@hubble/icons";
// eslint-disable-next-line no-restricted-imports
import { FormikActions, FormikErrors, FormikTouched } from "formik";
import queryString from "query-string";
import { useMedia } from "react-use";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import GRecaptcha from "@gemini-ui/components/GRecaptcha";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import {
  Button,
  Checkbox,
  Flex,
  Form,
  HubbleCard,
  HubbleListItem,
  IconBadge,
  Input,
  InputPassword,
  List,
  mediaQuery,
} from "@gemini-ui/design-system";
import { LimitTextLength } from "@gemini-ui/design-system/utils";
import { SardinePath } from "@gemini-ui/hooks/sardineUtils";
import useSardine from "@gemini-ui/hooks/useSardine";
import { testIds } from "@gemini-ui/pages/register/testIds";
import { AlternateSignInOptions } from "@gemini-ui/pages/SignIn/AlternateSignInOptions";
import { SignInFormValues } from "@gemini-ui/pages/SignIn/constants";
import { SignInView } from "@gemini-ui/pages/SignIn/index";
import { SigninWithPasskeyError } from "@gemini-ui/pages/SignIn/SigninWithPasskeyError";
import { ForgotPasswordLink } from "@gemini-ui/pages/SignIn/styles";
import { usePasskeySignin } from "@gemini-ui/pages/SignIn/usePasskeySignin";
import { useIntl } from "@gemini-ui/utils/intl";

interface SignInFormik<Values> {
  values: Values;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
  handleChange: (e) => void;
  isSubmitting: boolean;
  handleSubmit: (e?: FormEvent<HTMLFormElement>) => void;
  setFieldValue: FormikActions<Values>["setFieldValue"];
  siteKeyV2: string;
  siteKeyV3?: string;
  setRecaptcha: (ref) => void;
  onRecaptchaResolve: (formikMethods) => () => void;
  redirect?: string;
  signInView: SignInView;
  setSignInView: (view: SignInView) => void;
  handleBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

export const trackOpenForgotPasswordLink = () => {
  track(EVENTS.OPEN_FORGOT_PASSWORD_LINK.name);
};

const { ForgotPasswordController } = jsRoutes.controllers.security;
const makeForgotPasswordUrl = enteredEmail => ForgotPasswordController.enterEmailGet(enteredEmail).url;

export function SignInForm({
  values,
  errors,
  touched,
  handleChange,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  siteKeyV2,
  siteKeyV3,
  setRecaptcha,
  onRecaptchaResolve,
  redirect,
  signInView,
  setSignInView,
  handleBlur,
}: SignInFormik<SignInFormValues>) {
  useSardine(SardinePath.SIGNIN);

  const query = queryString.parse(decodeURIComponent(window.location.search));
  const passkeyTestingEnabled = Boolean(query.passkeyTesting);
  const isPasswordlessEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_CREATE_PASSKEYS_PASSWORDLESS
  );
  const showSignInRevamp = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_SHOW_SIGN_IN_REVAMP);
  const { errorType, setErrorType, retrySignin, startSignin, isLoading } = usePasskeySignin(
    redirect,
    passkeyTestingEnabled
  );

  const forgotPasswordUrl = makeForgotPasswordUrl(values.email);
  const { intl } = useIntl();
  const isMobileXsDown = useMedia(mediaQuery.mobileXsDown);

  const hasCaptcha = values["g-recaptcha-response-v2"] || values["g-recaptcha-response-v3"];
  const disableButton = isSubmitting || !values.email || !values.password || !hasCaptcha;
  // NOTE that we're relying on the default form submission rather than interrupting it

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedVal = event.target.value.trim();
    setFieldValue("email", trimmedVal);
  };

  const handleRememberEmailSelection = () => {
    const currentVal = values.rememberEmail;
    setFieldValue("rememberEmail", currentVal === "true" ? "false" : "true");
  };

  if (isPasswordlessEnabled && showSignInRevamp && signInView === "PRIMARY") {
    return (
      <Fragment>
        <Input
          name="email"
          onChange={onEmailChange}
          value={values.email}
          label={intl.formatMessage({ defaultMessage: "Email address" })}
          onBlur={handleBlur}
          error={touched.email && errors.email}
          data-testid={testIds.input.emailAddress}
          autoComplete="email"
          inputSize="lg"
          mt={2}
        />
        <Button.Primary
          data-testid={testIds.button.continueLoginForm}
          size="lg"
          cta={intl.formatMessage({ defaultMessage: "Continue" })}
          onClick={() => {
            if (values.email && !errors.email) {
              setSignInView("SECONDARY");
            }
          }}
          mt={3}
        />
        <Fragment>
          {(isPasswordlessEnabled || passkeyTestingEnabled) && (
            <AlternateSignInOptions startSignIn={startSignin} isLoading={isLoading} />
          )}
          <Flex justifyContent="center" mt={3}>
            <Checkbox
              data-testid={testIds.input.rememberEmailAddress}
              checked={values.rememberEmail === "true"}
              name="rememberEmail"
              value={values.rememberEmail}
              inputSize="sm"
              onChange={handleRememberEmailSelection}
            >
              {intl.formatMessage({ defaultMessage: "Remember my email address" })}
            </Checkbox>
          </Flex>
        </Fragment>
        {Boolean(errorType) && (
          <SigninWithPasskeyError
            errorType={errorType}
            onDismiss={() => setErrorType(null)}
            retry={() => {
              setErrorType(null);
              retrySignin();
            }}
          />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Form method="POST" onSubmit={handleSubmit}>
        <input type="hidden" name="csrfToken" value={values.csrfToken} />
        <input type="hidden" name="referral" value={values.referral} />
        {isPasswordlessEnabled && showSignInRevamp ? (
          <HubbleCard density="md" variant="filled" mt={2} data-testid={testIds.input.emailAddress}>
            <List>
              <HubbleListItem
                density="lg"
                left={<IconBadge icon={<IconProfileFilled />} />}
                right={
                  <Button.Tertiary
                    data-testid={testIds.button.changeEmailAddress}
                    cta={intl.formatMessage({ defaultMessage: "Change" })}
                    onClick={() => {
                      setSignInView("PRIMARY");
                      setFieldValue("g-recaptcha-response-v2", "");
                      setFieldValue("g-recaptcha-response-v3", "");
                    }}
                  />
                }
              >
                <LimitTextLength
                  hasTooltip
                  text={values.email}
                  addParentheses={false}
                  finalTextLength={isMobileXsDown ? 18 : 24}
                  size="md"
                />
              </HubbleListItem>
            </List>
          </HubbleCard>
        ) : (
          <Input
            name="email"
            onChange={onEmailChange}
            value={values.email}
            label={intl.formatMessage({ defaultMessage: "Email address" })}
            onBlur={handleBlur}
            error={touched.email && errors.email}
            data-testid={testIds.input.emailAddress}
            autoComplete="email"
            inputSize="lg"
            mt={2}
          />
        )}
        {!showSignInRevamp && (
          <Checkbox
            checked={values.rememberEmail === "true"}
            name="rememberEmail"
            value={values.rememberEmail}
            inputSize="sm"
            onChange={handleRememberEmailSelection}
          >
            {intl.formatMessage({ defaultMessage: "Remember my email address" })}
          </Checkbox>
        )}
        <InputPassword
          name="password"
          autoComplete="current-password"
          onChange={handleChange}
          value={values.password}
          label={intl.formatMessage({ defaultMessage: "Password" })}
          error={touched.password && errors.password}
          placeholder={null}
          data-testid={testIds.input.password}
          inputSize="lg"
          mt={2}
          mb={2}
        />
        <ForgotPasswordLink
          onClick={trackOpenForgotPasswordLink}
          data-testid={testIds.link.goToResetPassword}
          href={forgotPasswordUrl}
          bold
        >
          {intl.formatMessage({ defaultMessage: "Forgot password?" })}
        </ForgotPasswordLink>
        <GRecaptcha
          checkbox
          ref={setRecaptcha}
          siteKeyV2={siteKeyV2}
          siteKeyV3={siteKeyV3}
          onResolved={onRecaptchaResolve({ setFieldValue })}
          onExpired={() => {
            setFieldValue("g-recaptcha-response-v2", "");
            setFieldValue("g-recaptcha-response-v3", "");
          }}
        />
        <Button.Primary
          type="submit"
          loading={isSubmitting}
          disabled={disableButton}
          data-testid={testIds.button.submitLoginForm}
          size="lg"
          cta={intl.formatMessage({ defaultMessage: "Sign in" })}
          mt={2}
          style={{ width: "100%" }}
        />
      </Form>
      {(isPasswordlessEnabled || passkeyTestingEnabled) && (
        <AlternateSignInOptions startSignIn={startSignin} isLoading={isLoading} />
      )}
      {Boolean(errorType) && (
        <SigninWithPasskeyError
          errorType={errorType}
          onDismiss={() => setErrorType(null)}
          retry={() => {
            setErrorType(null);
            retrySignin();
          }}
        />
      )}
    </Fragment>
  );
}
